.app{
  width: 100vw;
  height: 100vh;
  position: relative;
}
video{
  position: relative;
  z-index: 1;
}
.wrapp{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(7, 51, 51, 0.6);
}

@media only screen and (max-width: 600px) {
  video{
    display: none;
  }
  .wrapp{
    background: rgba(7, 51, 51, 1);
  }
  .wrapp img{
    width: 75%;
  }
}